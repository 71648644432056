import { Card } from "../../../../ui/cards/Card";
import { Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../../../../../theme/colors";
import { WardRoundEntry } from "../../../../../model/ward-round/WardRoundEntry";
import RuleIcon from "@mui/icons-material/Rule";
import { ActionType } from "../../../../../model/enum/ActionType";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import { NextRoundArrow } from "./NextRoundArrow";
import { NumberOfOccurrences } from "./NumberOfOccurrences";
import { Edit } from "@mui/icons-material";
import { Transition } from "../../../../../theme/utils";
import { formatTimeString } from "../../../../../helpers/formatTimeString";

interface Props {
  wardRound: WardRoundEntry;
  onEdit: () => void;
  hideOffset?: boolean;
}

export const ScheduleItem = ({ hideOffset = false, ...props }: Props) => {
  return (
    <Stack gap={1} direction={"row"} alignItems={"center"} sx={{ position: "relative" }}>
      {!hideOffset && <NextRoundArrow days={props.wardRound.offsetDays} />}
      <Card
        sx={{
          height: 85,
          backgroundColor: Colors.grey100,
          transition: Transition.Default,
          "&:hover": {
            backgroundColor: Colors.primaryLightBg,
            color: Colors.primary,
            ".editIcon": {
              opacity: 1,
            },
          },
        }}
        variant={"contained"}
        onClick={props.onEdit}
      >
        <Stack direction={"row"} gap={2}>
          <Stack gap={1} sx={{ justifyContent: "center", alignItems: "start", height: "100%" }}>
            <Typography sx={{ marginBottom: 0, fontSize: 18 }} variant={"h3"}>
              {props.wardRound.wardRoundTemplate.title}
            </Typography>
            <Stack direction={"row"} gap={1}>
              <Chip
                label={
                  <Stack direction={"row"} gap={0.5}>
                    {props.wardRound.wardRoundTemplate.questionnaires.length}
                    <RuleIcon fontSize={"small"} />
                  </Stack>
                }
              />
              {props.wardRound.wardRoundTemplate.challenges.map((challenge) => {
                if (challenge.enabled) {
                  return <Chip key={challenge.type} label={challenge.type} />;
                }
              })}
              {props.wardRound.wardRoundTemplate.actions.map((action, index) => {
                if (action.enabled) {
                  switch (action.type) {
                    case ActionType.Medicine:
                      return (
                        <Chip
                          key={index}
                          label={
                            <Stack>
                              <LocalHospitalOutlinedIcon fontSize={"small"} />
                            </Stack>
                          }
                        />
                      );
                    case ActionType.Photo:
                      return (
                        <Chip
                          key={index}
                          label={
                            <Stack>
                              <PhotoCameraOutlinedIcon fontSize={"small"} />
                            </Stack>
                          }
                        />
                      );
                  }
                }
              })}
              {props.wardRound.start && (
                <Chip
                  label={
                    <Stack direction={"row"} gap={0.5}>
                      {formatTimeString(props.wardRound.start)}
                    </Stack>
                  }
                />
              )}
            </Stack>
          </Stack>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className={"editIcon"}
            sx={{ opacity: 0, transition: Transition.Default, color: Colors.primary }}
          >
            <Edit />
          </Stack>
        </Stack>
      </Card>
      {props.wardRound.numberOfOccurrences && <NumberOfOccurrences days={props.wardRound.numberOfOccurrences} />}
    </Stack>
  );
};
