import { api } from "../api";
import { parseStatisticsForBarChart } from "../../parsers/statistics/parseStatisticsForBarChart";
import { BarChartData } from "../../model/statistics/BarChart";
import { StatisticsPeriod } from "../../model/enum/StatisticsPeriod";
import { fromPeriodToSinceUntil } from "../../helpers/fromPeriodToSinceUntil";

export const getSleepStatistics = async (patientId: string, period: StatisticsPeriod): Promise<BarChartData[]> => {
  const { since, until } = fromPeriodToSinceUntil(period);
  const response = await api.services.patientChallenge.getPatientChallengesProgress(patientId, since, until);
  return parseStatisticsForBarChart(response.data.sleep, since, until);
};
