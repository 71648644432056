import { Remove } from "@mui/icons-material";
import { Colors } from "../../../theme/colors";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {
  critical?: boolean;
}

export const CriticalIcon = (props: Props) => {
  switch (props.critical) {
    case true:
      return <ErrorIcon sx={{ color: Colors.primary }} />;
    case false:
      return <CheckCircleIcon color={"success"} />;
    case undefined:
      return <Remove color={"secondary"} />;
  }
};
