import { useParams } from "react-router-dom";
import { useAsyncCall } from "react-hook-async-call";
import { getStatistics } from "../../../../services/statistics/getStatistics";
import { useOnMount } from "../../../../hooks/useOnMount";
import { useAlerts } from "../../../../hooks/useAlerts";
import { AbstractDataGrid } from "../../../ui/datagrid/AbstractDataGrid";
import { GridColDef } from "@mui/x-data-grid-premium";
import { isBloodPressureValue } from "../../../../model/challenge/BloodPressureValue";
import { formatBloodPressure } from "../../../../helpers/formatBloodPressure";
import { StatisticItem } from "../../../../model/statistics/StatisticItem";
import { formatDateTime } from "../../../../helpers/formatDateTime";
import { Loading } from "../../../ui/loading/Loading";

const columns: GridColDef<StatisticItem>[] = [
  {
    field: "date",
    headerName: "Datum",
    flex: 1,
    renderCell: (params) => formatDateTime(params.row.date),
  },
  {
    field: "type",
    headerName: "Typ",
    flex: 1,
  },
  {
    field: "value",
    headerName: "Hodnota",
    flex: 0.5,
    renderCell: (params) => (
      <strong>
        {isBloodPressureValue(params.row.value) ? formatBloodPressure(params.row.value) : params.row.value ?? "-"}
      </strong>
    ),
  },
];

export const PatientStatistics = () => {
  const { patientId } = useParams() as { patientId: string };
  const alerts = useAlerts();
  const [loadStatistics, data, loading] = useAsyncCall(getStatistics, [], undefined, alerts.error);

  useOnMount(() => {
    loadStatistics(patientId);
  });

  if (loading) return <Loading />;

  return <AbstractDataGrid rows={data} columns={columns} showCheckbox enableExport />;
};
