import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button } from "@mui/material";
import { useAsyncCall } from "react-hook-async-call";
import { useForm } from "react-hook-form";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import React, { useEffect } from "react";
import { useAlerts } from "../../../../hooks/useAlerts";
import { Tag } from "../../../../model/tag/Tag";
import { putTag } from "../../../../services/tags/putTag";

type Props = DialogProps & {
  tag: Tag;
  onSuccess: (tag: Tag) => void;
};

export const TagDialog = ({ tag, ...props }: Props) => {
  const alerts = useAlerts();
  const form = useForm<Tag>();

  const [editTag, , loadingEdit] = useAsyncCall(
    putTag,
    undefined,
    (tag) => {
      alerts.success("Štítek byl úspěšně uložen");
      props.onClose();
      props.onSuccess(tag);
    },
    alerts.error
  );

  useEffect(() => {
    form.reset(tag);
  }, [form, tag]);

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      width={"md"}
      title={"Upravit štítek"}
      buttons={
        <Button onClick={() => editTag(form.getValues())} disabled={loadingEdit || !form.formState.isValid}>
          Uložit
        </Button>
      }
    >
      <TextInput title={"Štítek"} name={"value"} form={form} required />
    </AbstractDialog>
  );
};
