import { useOnMount } from "../../../hooks/useOnMount";
import { BarChartData, BarChartItem } from "../../../model/statistics/BarChart";
import { useState } from "react";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { Colors } from "../../../theme/colors";
import "moment/locale/cs";
import moment from "moment";
import { FontFamilyHeadings, FontWeight } from "../../../theme/utils";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { StarIcon } from "../icons/StarIcon";
import { useBreakpointDown } from "../../../hooks/useBreakpointDown";

interface Props {
  data: BarChartData[];
  formatLabel?: (value: number) => string;
  variant?: "large" | "normal";
}

interface VariantSize {
  width: number;
  height: number;
  fontSize: number;
}

export const BarChart = ({ variant = "normal", ...props }: Props) => {
  const [items, setItems] = useState<BarChartItem[]>([]);
  const [focused, setFocusedData] = useState<BarChartData>();
  const [focusedId, setFocusedId] = useState<number>();
  const [size, setSize] = useState<VariantSize>({ height: 220, width: 50, fontSize: 15 });

  const isMobile = useBreakpointDown();

  const setFocused = (index: number, data: BarChartData) => {
    setFocusedData(data);
    setFocusedId(index);
  };

  useOnMount(() => {
    if (props.data && props.data.length !== 0) initChartItems();
  });

  const initChartItems = () => {
    const maximum = Math.max(...props.data.map((value) => value.value));
    const itemsWHeights = props.data.map((data) => {
      return {
        data,
        percentageHeight: (data.value * 100) / maximum,
      };
    });

    setItems(itemsWHeights);
    setFocused(itemsWHeights.length - 1, itemsWHeights[itemsWHeights.length - 1].data);
    if (variant === "large") setSize({ height: 374, width: 85, fontSize: 18 });
  };

  return items.length === 0 ? (
    <Typography>{"Nejsou k dispozici žádná data"}</Typography>
  ) : (
    <Stack>
      <Card
        sx={{
          marginTop: 2,
          border: `2px solid ${Colors.grey200}`,
          bgcolor: Colors.white,
          p: 0,
          fontWeight: FontWeight.SemiBold,
          boxShadow: "none",
        }}
      >
        <Stack alignItems={"center"} sx={{ backgroundColor: Colors.grey50 }} paddingBottom={1}>
          <Stack direction={"row"} height={isMobile ? 100 : size.height} mt={`${30}px`} gap={2} padding={2}>
            {items.map((item, index) => (
              <Stack
                onClick={() => {
                  setFocused(index, item.data);
                }}
                key={index}
                flex={1}
                height={"100%"}
                position={"relative"}
                marginTop={variant === "large" ? 1 : 0}
              >
                <Box
                  sx={{
                    height: `${item.percentageHeight}%`,
                    background: focusedId === index ? Colors.primary600 : Colors.primary500,
                    marginTop: "auto",
                    zIndex: 2,
                    position: "relative",
                    width: isMobile ? 25 : size.width,
                  }}
                >
                  {item.data.goalMet && (
                    <Stack
                      marginTop={variant === "large" ? -4 : -3}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <StarIcon variant={variant} />
                    </Stack>
                  )}
                </Box>
                {focusedId === index && (
                  <Box
                    sx={{
                      position: "absolute",
                      width: "2px",
                      background: Colors.primary600,
                      height: "100%",
                      left: "50%",
                      marginLeft: "-1px",
                    }}
                  />
                )}
              </Stack>
            ))}
          </Stack>
          <Stack direction={"row"} gap={2}>
            {items.map((item, id) => (
              <Typography
                key={id}
                width={isMobile ? 25 : size.width}
                textAlign={"center"}
                flex={1}
                fontSize={isMobile ? 12 : size.fontSize}
                fontWeight={FontWeight.Regular}
                fontFamily={FontFamilyHeadings}
              >
                {capitalizeFirstLetter(moment(item.data.date).locale("cs").format("ddd"))}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Divider sx={{ borderBottomWidth: "inherit", borderColor: "inherit" }} />
        {focused && (
          <Stack padding={2}>
            <Typography fontSize={18} fontWeight={FontWeight.Bold} color={Colors.primary600}>
              {props.formatLabel ? props.formatLabel(focused.value) : `${focused.value}`}
            </Typography>
            <Typography>{capitalizeFirstLetter(moment(focused.date).format("dddd, DD.MM.YYYY"))}</Typography>
            {focused.goalMet && (
              <Stack direction={"row"} alignItems={"center"} gap={1} mt={0.5}>
                <StarIcon variant={"normal"} sx={{ boxShadow: "none" }} />
                <Typography>Cíl splněn!</Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Card>
    </Stack>
  );
};
