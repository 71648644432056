import { Colors } from "../colors";
import { SxProps } from "@mui/material";
import { Border, FontFamilyHeadings, FontWeight } from "../utils";

export const outerBoxStyles: SxProps = {
  flexGrow: 1,
};

export const dataGridStyles: SxProps = {
  "--DataGrid-containerBackground": Colors.grey200,
  "--DataGrid-rowBorderColor": Colors.grey200,
  border: "none",
  fontSize: 13,
  background: "none",
  "& .MuiButton-root": {
    "&.MuiButton-sizeSmall": {
      boxShadow: "none",
      background: "none",
      color: Colors.primary,
      padding: "0 8px",
      "&:hover": {
        background: "none",
        color: Colors.primary,
      },
    },
  },
  ".MuiDataGrid-columnHeaders": {
    color: Colors.grey500,
    borderRadius: 1,
    overflow: "hidden",
    fontFamily: FontFamilyHeadings,
    marginBottom: "5px",
    ".MuiDataGrid-columnHeadersInner": {
      pb: "10px",
    },
  },
  ".MuiDataGrid-virtualScroller": {
    overflow: "visible !important",
  },
  ".MuiDataGrid-main": {
    overflow: "visible !important",
  },
  ".MuiDataGrid-columnHeader": {
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "&Title": {
      fontWeight: FontWeight.Bold,
    },
  },
  ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  ".MuiDataGrid-row": {
    borderRadius: 1,
    border: Border.Grey.Thin,
    backgroundColor: Colors.white,
    "&:hover": {
      backgroundColor: Colors.grey50,
    },
  },
  ".MuiDataGrid-cell": {
    border: 0,
    display: "flex",
    alignItems: "center",
  },
  ".MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
};

export const dataGridHideHeader: SxProps = {
  "& .MuiDataGrid-columnHeaders": { display: "none" },
  "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
};
