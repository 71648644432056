import { Stack } from "@mui/material";
import { Header } from "../../layout/Header";
import { useAsyncCall } from "react-hook-async-call";
import { useOnMount } from "../../../hooks/useOnMount";
import { Loading } from "../../ui/loading/Loading";
import { useParams } from "react-router-dom";
import { getPatient } from "../../../services/patient/getPatient";
import { PatientStatisticsTabs } from "./PatientStatisticsTabs";

export const PatientStatistics = () => {
  const { patientId } = useParams() as { patientId: string };
  const [getPatientCall, patientData, loading] = useAsyncCall(() => getPatient(patientId), undefined);

  useOnMount(() => {
    getPatientCall();
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Stack>
        <Header
          title={`Statistiky pacienta`}
          subtitle={`${patientData?.firstName} ${patientData?.lastName}`}
          showBackButton
        />
        <PatientStatisticsTabs />
      </Stack>
    </>
  );
};
