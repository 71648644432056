import { QuestionType } from "../../../../model/enum/QuestionType";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MonitorHeartOutlined from "@mui/icons-material/MonitorHeartOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
  questionType: QuestionType;
}

export const QuestionIcon = ({ questionType }: Props) => {
  switch (questionType) {
    case QuestionType.FreeTextAnswer:
      return <ChatOutlinedIcon />;
    case QuestionType.MultiChoiceAnswer:
      return <CheckBoxOutlinedIcon />;
    case QuestionType.NumberAnswer:
      return <LooksOneOutlinedIcon />;
    case QuestionType.Rating:
      return <StarHalfOutlinedIcon />;
    case QuestionType.WellBeing:
      return <SentimentSatisfiedOutlinedIcon />;
    case QuestionType.SingleChoiceAnswer:
      return <RadioButtonCheckedIcon />;
    case QuestionType.BloodPressure:
      return <MonitorHeartOutlined />;
    case QuestionType.Information:
      return <InfoOutlinedIcon />;
  }
};
