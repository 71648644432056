export enum QuestionType {
  SingleChoiceAnswer = "Výběr jedné možnosti",
  MultiChoiceAnswer = "Výběr více možností",
  FreeTextAnswer = "Volný text odpovědi",
  NumberAnswer = "Číselná odpověď",
  Rating = "Hodnocení",
  WellBeing = "Emoji",
  BloodPressure = "Krevní tlak",
  Information = "Informace pro pacienta",
}
