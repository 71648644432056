import React, { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  children: ReactNode;
  chips?: ReactNode;
}

export const ItemDetailHeader = (props: Props) => {
  return (
    <Stack mb={2} alignItems={"flex-start"} gap={0.5}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Typography variant={"h3"} mb={0}>
          {props.title}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          {props.children}
        </Stack>
      </Stack>
      {props.chips}
    </Stack>
  );
};
