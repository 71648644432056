import { FormInputProps } from "./props/FormInputProps";
import { TextField } from "@mui/material";
import { InputWrapper } from "./InputWrapper";

export const TextInput = <TFormValues,>({
  name,
  title,
  disabled = false,
  form,
  required = false,
  placeholder,
  inputProps,
  multiline,
  helperText,
  fieldError,
  hideTitle = false,
  shrinkTitle = false,
  inputRegex,
}: FormInputProps<TFormValues>) => {
  return (
    <InputWrapper title={hideTitle || shrinkTitle ? undefined : title} required={required}>
      <TextField
        id={name}
        type={"text"}
        size="small"
        multiline={multiline}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        helperText={helperText}
        slotProps={{ input: inputProps, inputLabel: shrinkTitle ? { shrink: true } : {} }}
        error={!!fieldError}
        onBeforeInput={
          inputRegex
            ? (e) => {
                const regex = new RegExp(inputRegex);
                if (!regex.test((e as unknown as InputEvent)?.data ?? "")) {
                  e.preventDefault();
                }
              }
            : undefined
        }
        {...form.register(name, {
          required: required,
        })}
        {...(shrinkTitle ? { label: title } : {})}
      />
    </InputWrapper>
  );
};
