import { PatientCareDtoOut } from "../../generated";
import { PatientCare } from "../../model/patient/PatientCare";
import { parseDate } from "../parseDate";

export const parsePatientCare = (data: PatientCareDtoOut): PatientCare => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    treatmentBegin: new Date(data.treatmentBegin),
    treatmentEnd: data.treatmentEnd ? new Date(data.treatmentEnd) : undefined,
    createdAt: new Date(data.createdAt),
    nextWardRoundDate: data.upcomingWardRoundOccurrence && parseDate(data.upcomingWardRoundOccurrence.occurredAt),
    isActive: data.isActive,
  };
};
