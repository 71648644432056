import { useAlerts } from "../../../../hooks/useAlerts";
import { useAsyncCall } from "react-hook-async-call";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { deleteTagCategory } from "../../../../services/tags/deleteTagCategory";
import { TagCategory } from "../../../../model/tag/TagCategory";

type Props = DialogProps & {
  tagCategory: TagCategory;
  onSuccess: () => void;
};

export const TagCategoryDeleteDialog = (props: Props) => {
  const alerts = useAlerts();

  const [deleteTagCategoryCall] = useAsyncCall(
    deleteTagCategory,
    undefined,
    () => {
      alerts.success("Kategorie smazána");
      props.onSuccess();
      props.onClose();
    },
    () => {
      alerts.error("Při mazání kategorie došlo k chybě");
    }
  );

  const handleDelete = async () => {
    await deleteTagCategoryCall(props.tagCategory.id);
  };

  const actionButtons = (
    <Button color={"error"} endIcon={<DeleteIcon />} onClick={handleDelete}>
      Smazat
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Smazat kategorii"} buttons={actionButtons}>
      Opravdu si přejete smazat kategorii {props.tagCategory.title}?
    </AbstractDialog>
  );
};
