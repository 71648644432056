import { Header } from "../../layout/Header";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useOnMount } from "../../../hooks/useOnMount";
import { getPatient } from "../../../services/patient/getPatient";
import { Loading } from "../../ui/loading/Loading";
import { useAsyncCall } from "react-hook-async-call";
import { WarningCard } from "../../ui/cards/WarningCard";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { EditCareDialog } from "./dialogs/EditCareDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { SelectCareDialog } from "./dialogs/SelectCareDialog";
import { useBreakpointDown } from "../../../hooks/useBreakpointDown";
import { joinNonEmptyItems } from "../../../helpers/joinNonEmptyItems";
import { Colors } from "../../../theme/colors";
import { PatientCareDetail } from "./PatientCareDetail";
import { Edit, ExpandMore } from "@mui/icons-material";
import { FontWeight } from "../../../theme/utils";
import { formatDateTime } from "../../../helpers/formatDateTime";
import { useDialog } from "../../../hooks/useDialog";
import { PatientDetailDialog } from "./dialogs/PatientDetailDialog";
import { Patient } from "../../../model/patient/Patient";
import { OnboardingDialog } from "./dialogs/OnboardingDialog";
import { OrganisationContext } from "../../../context/OrganisationContext";
import { DateTimeWithTip } from "../../layout/DateTimeWithTip";

export const PatientDetailPage = () => {
  const { patientId } = useParams() as { patientId: string };
  const { organisation } = useContext(OrganisationContext);

  const [editCareOpened, setEditCareOpened] = useState<boolean>(false);
  const [selectCareOpened, setSelectCareOpened] = useState<boolean>(false);
  const [getPatientCall, patientData, loadingPatient] = useAsyncCall(() => getPatient(patientId), undefined);

  const [openPatientDetailDialog] = useDialog((dialogProps) => (
    <PatientDetailDialog {...dialogProps} patient={patientData} onSuccess={getPatientCall} />
  ));
  const [openLoginInfoDialog] = useDialog((dialogProps, patient: Patient) => (
    <OnboardingDialog {...dialogProps} patient={patient} organisation={organisation} />
  ));

  const isTablet = useBreakpointDown("md");

  useOnMount(() => {
    getPatientCall();
  });

  if (loadingPatient || !patientData) {
    return <Loading />;
  }

  return (
    <>
      <Stack>
        <Header
          title={`${patientData.firstName} ${patientData.lastName}`}
          subtitle={
            <Stack
              direction={isTablet ? "column" : "row"}
              spacing={isTablet ? 0 : 2}
              alignItems={isTablet ? "flex-start" : "flex-end"}
            >
              <Box>{joinNonEmptyItems([patientData.sex, patientData.age, patientData.personalNumber ?? ""])}</Box>
              {patientData.lastActivityAt && (
                <Typography variant={"caption"}>
                  naposledy aktivní: <DateTimeWithTip date={patientData.lastActivityAt} />
                </Typography>
              )}
              {patientData.care?.treatmentBegin && (
                <Typography variant={"caption"}>
                  začátek aktuální péče: <DateTimeWithTip date={patientData.care.treatmentBegin} />
                </Typography>
              )}
            </Stack>
          }
          buttons={
            <IconButton onClick={openPatientDetailDialog} color={"primary"}>
              <Edit fontSize={"small"} />
            </IconButton>
          }
          showBackButton
        >
          {patientData.phoneNumber && (
            <Button
              startIcon={<LocalPhoneIcon />}
              variant={"outlined"}
              color={"secondary"}
              onClick={() => (window.location.href = `tel:${patientData.phoneNumber}`)}
            >
              {patientData.phoneNumber}
            </Button>
          )}
          {patientData.care && (
            <ButtonGroup variant={"contained"}>
              <Button disabled>
                <Box sx={{ color: Colors.black }}>{patientData.care.name}</Box>
              </Button>
              <Button onClick={() => setEditCareOpened(true)} endIcon={<EditOutlinedIcon />} color={"primary"}>
                Přizpůsobit péči
              </Button>
            </ButtonGroup>
          )}
          {(patientData.care || organisation?.loginType === "seacat") && (
            <Button onClick={() => openLoginInfoDialog(patientData)} variant={"text"} startIcon={<InfoOutlinedIcon />}>
              Informace pro pacienta
            </Button>
          )}
        </Header>
        <Stack gap={1}>
          {/* Critical patient warning */}
          <WarningCard patientData={patientData} />

          {/* Current patient care */}
          {(() => {
            if (patientData.care) {
              return <PatientCareDetail care={patientData.care} />;
            }

            return (
              <Alert
                color={"info"}
                icon={<InfoOutlinedIcon />}
                action={
                  <Button
                    onClick={() => setSelectCareOpened(true)}
                    color="info"
                    size="small"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Vybrat šablonu péče
                  </Button>
                }
              >
                Pacient nemá nastavenou žádnou šablonu péče
              </Alert>
            );
          })()}

          {/* Ended patient cares */}
          {patientData.endedCares && patientData.endedCares.length > 0 && (
            <Stack mt={2}>
              <Divider />
              <Typography variant={"h4"} my={2}>
                Ukončené péče ({patientData.endedCares.length})
              </Typography>
              {patientData.endedCares.map((care) => (
                <Accordion key={care.id} disableGutters elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography fontWeight={FontWeight.Bold} color={Colors.blueGrey800}>
                      {care.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {care.treatmentEnd && (
                      <Alert color={"info"} sx={{ mb: 3 }}>
                        Péče byla ukončena {formatDateTime(care.treatmentEnd)}
                      </Alert>
                    )}
                    <PatientCareDetail care={care} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
      <EditCareDialog
        open={editCareOpened}
        patient={patientData}
        onCareEnd={() => {
          setEditCareOpened(false);
          getPatientCall();
        }}
        onClose={setEditCareOpened}
      />
      <SelectCareDialog
        open={selectCareOpened}
        onClose={setSelectCareOpened}
        patient={patientData}
        onSubmit={getPatientCall}
      />
    </>
  );
};
