import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";
import { Colors } from "../colors";
import { Border, FontWeight } from "../utils";

export const MuiButtonOverrides: {
  defaultProps?: ComponentsProps["MuiButton"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiButton"];
} = {
  defaultProps: {
    variant: "contained",
  },
  styleOverrides: {
    root: {
      fontWeight: FontWeight.Bold,
      textTransform: "none",
      padding: "7px 16px",
      whiteSpace: "nowrap",
      boxShadow: "none",
      transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
      "&:hover": {
        boxShadow: "none",
        filter: "brightness(95%)",
      },
      "& .MuiSvgIcon-root": {
        width: 14,
      },
      "&.MuiButton-containedPrimary": {
        "&:hover": {
          background: Colors.primary,
        },
      },
      "&.MuiButton-containedSuccess": {
        color: Colors.white,
      },
      "&.MuiButton-containedSecondary": {
        color: Colors.primary,
        background: Colors.primaryLightBg,
      },
      "&.MuiButton-textPrimary:not(:disabled)": {
        color: Colors.primary,
      },
      "&.MuiButton-textSecondary": {
        color: Colors.grey500,
        background: Colors.grey50,
      },
      "&.MuiButton-outlinedSecondary": {
        color: Colors.black,
        background: Colors.white,
        border: Border.Grey.Thin,
      },
    },
    endIcon: {
      marginLeft: 8,
      transition: "transform 500ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    sizeSmall: {
      minWidth: 0,
    },
    sizeLarge: {
      height: 50,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
};
