import { FormInputProps } from "./props/FormInputProps";
import { TextField } from "@mui/material";
import { InputWrapper } from "./InputWrapper";

export type Props<TFormValues> = FormInputProps<TFormValues> & {
  step?: number;
  min?: number;
  max?: number;
  onlyInt?: boolean;
};

export const NumberInput = <TFormValues,>({
  inputProps,
  name,
  title,
  disabled = false,
  form,
  required = false,
  step = 1,
  min,
  max,
  onlyInt = false,
  helperText,
  fieldError,
  hideTitle = false,
}: Props<TFormValues>) => {
  return (
    <InputWrapper title={hideTitle ? undefined : title} required={required} sx={{ flex: "none" }}>
      <TextField
        id={name}
        type={"number"}
        size="small"
        disabled={disabled}
        required={required}
        slotProps={{ input: inputProps, htmlInput: { step } }}
        error={!!fieldError}
        helperText={fieldError?.message ?? helperText}
        {...form.register(name, {
          setValueAs: (value) => {
            const numericValue = parseFloat(value);
            return Number.isNaN(numericValue) ? undefined : numericValue;
          },
          required: required && `Pole ${title?.toLowerCase()} nesmí být prázdné`,
          validate: {
            min: (value) => {
              if (typeof min === "number" && value < min) {
                return "Hodnota musí být větší nebo rovna " + min.toString();
              }
            },
            max: (value) => {
              if (typeof max === "number" && value > max) {
                return "Hodnota musí být menší nebo rovna " + max.toString();
              }
            },
            onlyInt: (value) => {
              if (onlyInt && typeof value === "number" && value % 1 !== 0) {
                return "Musí být celé číslo";
              }
            },
          },
        })}
      />
    </InputWrapper>
  );
};
