import { TagCreate } from "../../../../model/tag/Tag";
import { TagCategory } from "../../../../model/tag/TagCategory";
import { useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import React from "react";
import { useAsyncCall } from "react-hook-async-call";
import { postTag } from "../../../../services/tags/postTag";
import { useAlerts } from "../../../../hooks/useAlerts";

type Props = {
  tagCategory: TagCategory;
  onSuccess: () => void;
};

export const NewTagForm = (props: Props) => {
  const alerts = useAlerts();
  const form = useForm<TagCreate>();

  const [addTag, , loading] = useAsyncCall(
    postTag,
    undefined,
    () => {
      alerts.success("Štítek byl úspěšně přidán");
      props.onSuccess();
    },
    alerts.error
  );

  const handleAdd = () => {
    addTag(props.tagCategory.id, form.getValues());
    form.reset({ value: "" });
  };

  return (
    <Stack
      direction="row"
      gap={2}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleAdd();
        }
      }}
    >
      <TextInput title={"Přidat štítek..."} name={"value"} form={form} shrinkTitle required />
      <Button onClick={handleAdd} size={"small"} color={"success"} disabled={loading || !form.formState.isValid}>
        Přidat
      </Button>
    </Stack>
  );
};
