import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { useAsyncCall } from "react-hook-async-call";
import { getAllCareTemplates } from "../../../../services/care-template/getAllCareTemplates";
import { useOnMount } from "../../../../hooks/useOnMount";
import { SelectInput } from "../../../ui/form/inputs/SelectInput";
import { useForm } from "react-hook-form";
import { Alert, Button, Stack } from "@mui/material";
import { Patient } from "../../../../model/patient/Patient";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { postAssignCare } from "../../../../services/patient-care/postAssignCare";
import { Loading } from "../../../ui/loading/Loading";
import { useAlerts } from "../../../../hooks/useAlerts";
import { CareAssignment } from "../../../../model/patient/CareAssignment";
import { DateInput } from "../../../ui/form/inputs/DateInput";
import { formatDateTime } from "../../../../helpers/formatDateTime";
import { SelectInputOption } from "../../../ui/form/inputs/props/SelectInputProps";
import { CareTemplate } from "../../../../model/templates/CareTemplate";
import { CareType } from "../../../../model/enum/CareType";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { NavigationRoutes } from "../../../../model/constants/NavigationRoutes";

interface Props {
  open: boolean;
  onClose: (opened: boolean) => void;
  onSubmit: () => void;
  patient?: Patient;
  patientIds?: string[];
}

export const SelectCareDialog = ({ open, onClose, patient, patientIds, onSubmit }: Props) => {
  const alerts = useAlerts();
  const form = useForm<CareAssignment>({ defaultValues: { date: new Date() } });
  const careTemplate = form.watch("careTemplate");
  const date = form.watch("date");

  const [getAllCare, allCare, fetchLoading] = useAsyncCall(getAllCareTemplates, []);
  const [assignCare, , assignLoading] = useAsyncCall(
    postAssignCare,
    undefined,
    () => {
      alerts.success("Pacientovi byla úspěšně nastavená péče");
      onSubmit();
      onClose(false);
    },
    alerts.error
  );

  useOnMount(() => {
    getAllCare();
  });

  const options: SelectInputOption<CareTemplate>[] = allCare.map((care) => ({
    value: care,
    label: care.title,
    key: care.id,
    chip: care.type === CareType.Preoperative ? care.type : undefined,
  }));

  const handleAssign = () => {
    const ids = patient ? [patient.id] : patientIds ?? [];
    Promise.all(ids.map((id) => assignCare(id, form.getValues())));
  };

  const buttons = (
    <Button
      onClick={() => {
        handleAssign();
      }}
      color={"success"}
      endIcon={<CheckOutlinedIcon />}
      disabled={!form.formState.isValid || assignLoading}
    >
      Nastavit
    </Button>
  );

  const isSelectedPreoperative = careTemplate?.type === CareType.Preoperative;

  return (
    <AbstractDialog open={open} onClose={onClose} title={"Výběr šablony péče"} width={"md"} buttons={buttons}>
      {(() => {
        if (fetchLoading) {
          return <Loading />;
        }

        return (
          <>
            <Stack direction="row" spacing={2} alignItems="flex-end">
              <SelectInput
                name={"careTemplate"}
                form={form}
                options={options}
                title={"Šablona péče"}
                required
                optionsValueComparator={(a, b) => a?.id === b?.id}
              />
              {careTemplate && (
                <Button
                  endIcon={<OpenInNewIcon />}
                  color={"secondary"}
                  sx={{ marginBottom: "5px !important" }}
                  href={NavigationRoutes.wardRoundTemplateDetail.getLink(careTemplate.id)}
                  target={"_blank"}
                >
                  Detail šablony péče
                </Button>
              )}
            </Stack>
            {careTemplate && (
              <Stack gap={2}>
                <DateInput
                  name={"date"}
                  form={form}
                  title={isSelectedPreoperative ? "Termín zákroku" : "Datum začátku péče"}
                  type={"date"}
                  required
                  helperText={
                    isSelectedPreoperative
                      ? "Termín zákroku či datum ukončení péče. Všechny vizity se naplánuji PŘED uvedeným datem."
                      : "Datum začátku péče či termín zákroku. Všechny vizity se naplánuji PO uvedeném datu."
                  }
                />
                <Alert color={"success"}>
                  {patient ? (
                    <>
                      Pacientovi{" "}
                      <strong>
                        {patient.firstName} {patient.lastName}
                      </strong>
                    </>
                  ) : (
                    <>
                      <strong>{patientIds?.length}</strong> pacientům
                    </>
                  )}{" "}
                  bude nastavená péče <strong>{careTemplate.title}</strong>. Všechny vizity se naplánuji{" "}
                  <u>{isSelectedPreoperative ? "před" : "po"}</u>{" "}
                  <strong>{formatDateTime(date, "DD.MM.YYYY HH:mm")}</strong>.
                </Alert>
              </Stack>
            )}
          </>
        );
      })()}
    </AbstractDialog>
  );
};
