import { AnswerTemplateDtoOut } from "../../generated";
import { AnswerTemplate } from "../../model/templates/AnswerTemplate";
import { parseSeverityStatus } from "./parseSeverityStatus";

export const parseAnswerTemplate = (data: AnswerTemplateDtoOut): AnswerTemplate => ({
  id: data.id,
  text: data.text,
  severity: parseSeverityStatus(data.severity),
  alertNote: data.alertNote,
});
