import { api } from "../api";
import { Tag } from "../../model/tag/Tag";
import { parseTag } from "../../parsers/tags/parseTag";

export const putTag = async (data: Tag): Promise<Tag> => {
  const response = await api.services.tag.updateTag(data.id, {
    value: data.value,
    categoryId: data.category.id,
  });
  return parseTag(response.data);
};
