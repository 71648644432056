import {
  ChallengeProgressSummaryDtoOut,
  ProgressDtoOutBloodPressureDtoInOut,
  ProgressDtoOutDouble,
} from "../../generated";
import { StatisticItem } from "../../model/statistics/StatisticItem";
import { NumericStatistic } from "../../model/statistics/NumericStatistic";
import { BaseStatistic } from "../../model/statistics/BaseStatistic";
import { v4 as uuidv4 } from "uuid";
import { BloodPressureStatistic } from "../../model/statistics/BloodPressureStatistic";
import { StatisticType } from "../../model/statistics/StatisticType";
import { sortByDate } from "../../helpers/sortByDate";

export const parseStatistics = (data: ChallengeProgressSummaryDtoOut): StatisticItem[] => {
  const water = data.water.progress.map((item) => parseNumericStatistic(item, StatisticType.HeartRate));
  const steps = data.steps.progress.map((item) => parseNumericStatistic(item, StatisticType.HeartRate));
  const sleep = data.sleep.progress.map((item) => parseNumericStatistic(item, StatisticType.HeartRate));
  const heartRate = data.heart.progress.map((item) => parseNumericStatistic(item, StatisticType.HeartRate));
  const bloodPressure = data.bloodPressure.progress.map(parseBloodPressureStatistic);

  return sortByDate([...water, ...sleep, ...steps, ...heartRate, ...bloodPressure], "desc");
};

const parseBaseStatistic = (
  data: ProgressDtoOutDouble | ProgressDtoOutBloodPressureDtoInOut,
  type: StatisticType
): BaseStatistic => {
  return {
    id: uuidv4(),
    date: new Date(data.timestamp),
    type,
  };
};

const parseNumericStatistic = (data: ProgressDtoOutDouble, type: StatisticType): NumericStatistic => {
  return {
    ...parseBaseStatistic(data, type),
    value: data.value,
  };
};

const parseBloodPressureStatistic = (data: ProgressDtoOutBloodPressureDtoInOut): BloodPressureStatistic => {
  return {
    ...parseBaseStatistic(data, StatisticType.Pressure),
    value: data.value, // todo: use parser
  };
};
