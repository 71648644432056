import { Border, FontWeight, PagePadding, RemovePagePadding } from "../../theme/utils";
import React, { ReactNode } from "react";
import { Button, Stack } from "@mui/material";
import { NoData } from "./NoData";
import { Colors } from "../../theme/colors";
import { ChevronRight } from "@mui/icons-material";
import { DataItem } from "../../model/DataItem";
import { Header } from "./Header";

interface Props<T extends DataItem> {
  title: string;
  items: T[];
  selectedItem?: T;
  headerButtons: ReactNode;
  itemDetail: ReactNode;
  onItemClick: (item: T) => void;
}

export const ItemList = <T extends DataItem>(props: Props<T>) => {
  return (
    <Stack height={"100%"}>
      <Header title={props.title}>{Boolean(props.items.length) && props.headerButtons}</Header>
      <Stack direction={"row"} height={"100%"} sx={{ ...RemovePagePadding, pt: PagePadding.py }}>
        {(() => {
          if (!props.items.length || !props.selectedItem) {
            return <NoData newItemButton={props.headerButtons} />;
          }
          return (
            <>
              {/* ITEM LIST */}
              <Stack
                flex={1}
                height={"100%"}
                bgcolor={Colors.grey50}
                minWidth={300}
                sx={{
                  border: Border.Grey.Thin,
                  borderLeft: "none",
                  borderBottom: "none",
                  overflowY: "auto",
                }}
              >
                {props.items.map((item) => {
                  const selected = item.id === props.selectedItem?.id;

                  return (
                    <Button
                      key={item.id}
                      onClick={() => props.onItemClick(item)}
                      variant={"outlined"}
                      sx={{
                        color: selected ? Colors.primary : Colors.black,
                        background: selected ? Colors.primaryLightBg : Colors.white,
                        px: PagePadding.px,
                        fontWeight: FontWeight.SemiBold,
                        borderRadius: 0,
                        border: "0!important",
                        borderBottom: `${Border.Grey.Thin} !important`,
                        ".endIcon": {
                          opacity: selected ? 1 : 0,
                        },
                        ":hover": {
                          background: selected ? "" : "transparent",
                          ".endIcon": { opacity: `${selected ? 1 : 0.5} !important` },
                        },
                      }}
                    >
                      <Stack justifyContent={"space-between"} direction={"row"} width={"100%"}>
                        <Stack>{item.title}</Stack>
                        <Stack
                          className={"endIcon"}
                          sx={{
                            opacity: 0,
                            transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
                          }}
                          mr={-1}
                        >
                          <ChevronRight
                            sx={{
                              width: "20px !important",
                              height: "100%",
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Button>
                  );
                })}
              </Stack>

              {/* ITEM DETAIL */}
              <Stack
                flex={5}
                bgcolor={"white"}
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  px: 3,
                  pt: 1.5,
                  pb: 3,
                  borderTop: Border.Grey.Thin,
                  overflowY: "scroll",
                }}
              >
                {props.itemDetail}
              </Stack>
            </>
          );
        })()}
      </Stack>
    </Stack>
  );
};
