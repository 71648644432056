import { DateInputProps } from "./props/FormInputProps";
import { InputWrapper } from "./InputWrapper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, FieldValues } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

export const DateInput = <TFormValues extends FieldValues>({
  disabled = false,
  required = false,
  ...props
}: DateInputProps<TFormValues>) => {
  return (
    <InputWrapper title={props.title} required={required}>
      <Controller
        name={props.name}
        control={props.form.control}
        rules={{ required }}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="cs">
            <DateTimePicker
              value={field.value ? moment(field.value) : null}
              onChange={(value) => {
                field.onChange(value === null ? undefined : value.toDate());
              }}
              slotProps={{
                textField: { sx: { ".MuiInputBase-input": { py: "8.5px" } }, helperText: props.helperText },
              }}
              disabled={disabled}
            />
          </LocalizationProvider>
        )}
      />
    </InputWrapper>
  );
};
