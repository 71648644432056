import { WardRoundBlock } from "../WardRoundBlock";
import { AbstractDataGrid } from "../../../../ui/datagrid/AbstractDataGrid";
import { Stack } from "@mui/material";
import { BendedLine } from "../../../../ui/perks/BendedLine";
import { PhotoActionResponse } from "../../../../../model/actions/ActionResponse";
import { ActionType } from "../../../../../model/enum/ActionType";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import { Transition } from "../../../../../theme/utils";
import { GridColDef } from "@mui/x-data-grid-premium";

const columns: GridColDef<PhotoActionResponse>[] = [
  {
    field: "title",
    headerName: "Název",
    flex: 1,
    renderCell: (params) => <strong>{params.row.fileName}</strong>,
  },
];

interface Props {
  data: PhotoActionResponse;
}

export const WardRoundPhotoAction = ({ data }: Props) => {
  const [openLightbox, setOpenLightbox] = useState<boolean>(false);

  return (
    <WardRoundBlock title={ActionType.Photo}>
      <Stack gap={1}>
        <AbstractDataGrid
          rows={[data]}
          columns={columns}
          getRowId={(row) => row.fileId}
          disableColumnSorting
          disableColumnMenu
        />
        <Stack direction={"row"} gap={2} ml={2}>
          <BendedLine size={16} />
          <Stack
            onClick={() => setOpenLightbox(true)}
            maxHeight={200}
            maxWidth={300}
            borderRadius={1}
            overflow={"hidden"}
            sx={{
              transition: Transition.Default,
              cursor: "pointer",
              "&:hover": {
                opacity: 0.7,
              },
            }}
          >
            <img src={`data:image/jpeg;base64,${data.content}`} alt={data.fileName} />
          </Stack>
          <Lightbox
            open={openLightbox}
            close={() => setOpenLightbox(false)}
            plugins={[Zoom, Download]}
            slides={[
              {
                src: `data:image/jpeg;base64,${data.content}`,
              },
            ]}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </Stack>
      </Stack>
    </WardRoundBlock>
  );
};
