import { AnsweredQuestionDtoOut } from "../../generated";
import { AnsweredQuestion } from "../../model/questions/AnsweredQuestion";
import { parseQuestion } from "./parseQuestion";
import { parseBloodPressure } from "./parseBloodPressure";

export const parseAnsweredQuestion = (data: AnsweredQuestionDtoOut): AnsweredQuestion => {
  return {
    question: parseQuestion(data.question),
    userAnswers: data.userAnswers.map((x) => x.answerText),
    userNote: data.note,
    bloodPressure: data.bloodPressure && parseBloodPressure(data.bloodPressure),
  };
};
