import { Header } from "../../layout/Header";
import { Alert, Button, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useAsyncCall } from "react-hook-async-call";
import { getAllPatients } from "../../../services/patient/getAllPatients";
import { useOnMount } from "../../../hooks/useOnMount";
import { Loading } from "../../ui/loading/Loading";
import { useAlerts } from "../../../hooks/useAlerts";
import { PatientListColumns } from "./PatientListColumns";
import { AbstractDataGrid } from "../../ui/datagrid/AbstractDataGrid";
import { SelectCareDialog } from "../patient-detail/dialogs/SelectCareDialog";
import { postEndCare } from "../../../services/patient-care/postEndCare";
import { Check, Close, OpenInNew } from "@mui/icons-material";
import { useDialog } from "../../../hooks/useDialog";
import { PatientDetailDialog } from "../patient-detail/dialogs/PatientDetailDialog";
import AddIcon from "@mui/icons-material/Add";
import { PatientSearch } from "./PatientSearch";
import { PatientSearchFormValue } from "../../../model/patient/PatientSearchFormValue";
import { useNavigate } from "react-router-dom";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import { OrganisationContext } from "../../../context/OrganisationContext";

const defaultPageSize = 10;

export const PatientsPage = () => {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const { organisation } = useContext(OrganisationContext);

  const [openNewPatientDialog] = useDialog((dialogProps) => <PatientDetailDialog {...dialogProps} />);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectCareOpened, setSelectCareOpened] = useState<boolean>(false);

  const [getPatientList, patientList, loading] = useAsyncCall(
    (page, pageSize, searchValue?: PatientSearchFormValue) => getAllPatients(page, pageSize, searchValue),
    undefined,
    undefined,
    (e) => alerts.error(e)
  );
  const [endCare] = useAsyncCall(
    postEndCare,
    undefined,
    () => {
      alerts.success(`Péče byla úspěšně ukončena.`);
    },
    (e) => alerts.error(e)
  );

  const patients = patientList ? patientList.patients : [];

  useOnMount(() => {
    getPatientList(0, defaultPageSize);
  });

  const handlePaginationChange = (model: GridPaginationModel) => {
    getPatientList(model.page, model.pageSize);
  };

  const handleEndCareClick = async () => {
    if (confirm("Ukončit péči vybraným pacientům?")) {
      await Promise.all(selectedIds.map(endCare));
      getPatientList(0, defaultPageSize);
      setSelectedIds([]);
    }
  };

  return (
    <>
      <Header title={"Moji pacienti"}>
        {organisation?.loginType === "default" && (
          <Button onClick={openNewPatientDialog} endIcon={<AddIcon />}>
            Nový pacient
          </Button>
        )}
        <Button onClick={() => navigate("/tags")} variant={"outlined"} endIcon={<OpenInNew />}>
          Štítky pacientů
        </Button>
      </Header>

      <PatientSearch loading={loading} onSearch={(value) => getPatientList(0, defaultPageSize, value)} />

      {(() => {
        if (loading) {
          return <Loading />;
        } else if (patientList) {
          return (
            <>
              {selectedIds.length > 0 && (
                <Alert sx={{ mb: 1 }}>
                  <Stack direction={"row"} flex={1} width={"100%"} alignItems={"center"}>
                    <Stack flex={1}>Vybráno {selectedIds.length} pacientů</Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <Button
                        onClick={() => {
                          setSelectCareOpened(true);
                        }}
                        endIcon={<Check />}
                      >
                        Nastavit péči
                      </Button>
                      <Button onClick={handleEndCareClick} variant={"outlined"} endIcon={<Close />}>
                        Ukončit péči
                      </Button>
                    </Stack>
                  </Stack>
                </Alert>
              )}
              <AbstractDataGrid
                rows={patients}
                columns={PatientListColumns}
                noRowsMessage={"Nejsou nalezení žádní pacienti"}
                page={patientList.page}
                pageSize={patientList.pageSize}
                totalElements={patientList.totalElements}
                onPaginationChange={handlePaginationChange}
                showCheckbox
                disableColumnSorting
                disableColumnMenu
                selectedIds={selectedIds}
                onSelectedIdsChange={setSelectedIds}
              />
              {selectedIds.length > 0 && (
                <SelectCareDialog
                  open={selectCareOpened}
                  onClose={setSelectCareOpened}
                  patientIds={selectedIds}
                  onSubmit={() => {
                    getPatientList(0, defaultPageSize);
                  }}
                />
              )}
            </>
          );
        }
      })()}
    </>
  );
};
