import { QuestionTemplate, QuestionTemplateBase } from "../../model/templates/QuestionTemplate";
import { QuestionBaseTemplateDtoOut, QuestionConditionTemplateDtoOut, QuestionTemplateDtoOut } from "../../generated";
import { parseQuestionType } from "../question/parseQuestionType";
import { parseAnswerTemplate } from "./parseAnswerTemplate";
import { QuestionCondition } from "../../model/templates/QuestionCondition";

export const parseQuestionTemplateBase = (data: QuestionBaseTemplateDtoOut): QuestionTemplateBase => ({
  id: data.id,
  type: parseQuestionType(data.type),
  text: data.text,
  answers: data.answers.map(parseAnswerTemplate),
  required: data.required,
});

export const parseQuestionTemplate = (data: QuestionTemplateDtoOut): QuestionTemplate => ({
  ...parseQuestionTemplateBase(data),
  conditions: data.conditions.map(parseQuestionCondition),
});

const parseQuestionCondition = (data: QuestionConditionTemplateDtoOut): QuestionCondition => ({
  answer: parseAnswerTemplate(data.answerTemplate),
  question: parseQuestionTemplateBase(data.questionTemplate),
});
