import { Stack, Switch } from "@mui/material";
import { FontWeight } from "../../../../theme/utils";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  title?: string;
  checked: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
  bold?: boolean;
}

export const SwitchInput = ({ title, onChange, checked, disabled = false, bold = false }: Props) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} height={28}>
      <Switch
        sx={{ ml: -1.2 }}
        color={"primary"}
        checked={checked}
        disabled={disabled}
        onChange={(_, checked) => {
          onChange(checked);
        }}
      />
      <Stack flexGrow={1} fontWeight={bold ? FontWeight.Bold : FontWeight.SemiBold}>
        {title}
      </Stack>
    </Stack>
  );
};
