import { ItemList } from "../../layout/ItemList";
import React, { useEffect, useState } from "react";
import { useAsyncCall } from "react-hook-async-call";
import { getTagCategories } from "../../../services/tags/getTagCategories";
import { useAlerts } from "../../../hooks/useAlerts";
import { TagCategory } from "../../../model/tag/TagCategory";
import { TagCategoryDetailPage } from "./TagCategoryDetailPage";
import { useOnMount } from "../../../hooks/useOnMount";
import { Loading } from "../../ui/loading/Loading";
import { useDialog } from "../../../hooks/useDialog";
import { TagCategoryDialog } from "./dialogs/TagCategoryDialog";
import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import AddIcon from "@mui/icons-material/Add";
import { getTags } from "../../../services/tags/getTags";

export const TagCategoriesPage = () => {
  const alerts = useAlerts();

  const [selectedTagCategory, setSelectedTagCategory] = useState<TagCategory>();
  const [loadTagCategories, tagCategories, loading] = useAsyncCall(getTagCategories, [], undefined, alerts.error);
  const [loadTags, tags, loadingTags] = useAsyncCall(getTags, [], undefined, alerts.error);
  const [openNewTagCategoryDialog] = useDialog((dialogProps) => (
    <TagCategoryDialog
      {...dialogProps}
      onSuccess={(addedCategory) => {
        loadTagCategories();
        setSelectedTagCategory(addedCategory);
      }}
    />
  ));

  useOnMount(() => {
    loadTagCategories();
    loadTags();
  });

  useEffect(() => {
    if (!selectedTagCategory && tagCategories.length) {
      setSelectedTagCategory(tagCategories[0]);
    }
  }, [selectedTagCategory, tagCategories]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ItemList
        title={"Štítky pacientů"}
        items={tagCategories}
        selectedItem={selectedTagCategory}
        headerButtons={
          <AnimatedButton
            onClick={openNewTagCategoryDialog}
            animation={"scale"}
            endIcon={<AddIcon />}
            variant={"contained"}
          >
            Nová kategorie štítků
          </AnimatedButton>
        }
        itemDetail={
          selectedTagCategory && (
            <TagCategoryDetailPage
              tagCategory={selectedTagCategory}
              loadingTags={loadingTags}
              tags={tags.filter((tag) => tag.category.id === selectedTagCategory.id)}
              onUpdate={(updatedTagCategory) => {
                loadTagCategories();
                setSelectedTagCategory(updatedTagCategory);
              }}
              onDelete={() => {
                loadTagCategories();
                setSelectedTagCategory(undefined);
              }}
              onTagUpdate={loadTags}
            />
          )
        }
        onItemClick={setSelectedTagCategory}
      />
    </>
  );
};
