import { createTheme, ThemeOptions } from "@mui/material";
import { FontFamilyHeadings, FontFamilyParagraph, FontWeight } from "./utils";
import { Colors } from "./colors";
import { MuiButtonOverrides } from "./overrides/MuiButtonOverrides";
import { MuiTabsOverrides } from "./overrides/MuiTabsOverrides";
import { MuiTabOverrides } from "./overrides/MuiTabOverrides";
import { MuiFormHelperText } from "./overrides/MuiFormHelperText";
import { MuiTextField } from "./overrides/MuiTextField";
import { MuiChipOverrides } from "./overrides/MuiChipOverrides";
import { MuiAlertOverrides } from "./overrides/MuiAlertOverrides";
import { MuiButtonGroupOverrides } from "./overrides/MuiButtonGroupOverrides";
import { MuiAccordionOverrides } from "./overrides/MuiAccordionOverrides";
import { csCZ } from "@mui/x-data-grid-premium/locales";
import { csCZ as pickersCsCZ } from "@mui/x-date-pickers/locales";
import { csCZ as coreCsCZ } from "@mui/material/locale";

export const appTheme: ThemeOptions = createTheme(
  {
    spacing: 10,
    typography: {
      fontFamily: FontFamilyParagraph,
      fontWeightRegular: 500,
      fontSize: 12.25,
      h1: {
        fontFamily: FontFamilyHeadings,
        fontSize: 36,
        fontWeight: FontWeight.ExtraBold,
        marginTop: 0,
        marginBottom: 20,
      },
      h2: {
        fontFamily: FontFamilyHeadings,
        fontSize: 28,
        fontWeight: FontWeight.ExtraBold,
      },
      h3: {
        fontFamily: FontFamilyParagraph,
        fontSize: 24,
        fontWeight: FontWeight.Bold,
        marginTop: 0,
        marginBottom: 10,
      },
      h4: {
        fontFamily: FontFamilyHeadings,
        fontSize: 14,
        fontWeight: FontWeight.Bold,
        marginTop: 0,
        marginBottom: 10,
      },
      caption: {
        fontSize: 12,
        marginBottom: "5px",
        color: Colors.blueGrey800,
      },
    },
    palette: {
      primary: {
        main: Colors.primary,
      },
      secondary: {
        main: Colors.grey500,
        contrastText: Colors.grey800,
      },
      success: {
        main: Colors.success,
      },
      yellow: {
        main: Colors.yellowLightBg,
        contrastText: Colors.black,
      },
      blue: {
        main: Colors.blueLightBg,
        contrastText: Colors.blue,
      },
      blueGrey800: {
        main: Colors.blueGrey600,
        contrastText: Colors.blueGrey800,
      },
      error: {
        main: Colors.error,
      },
      green: {
        main: Colors.green,
        contrastText: Colors.white,
        light: Colors.green,
        dark: Colors.green,
      },
    },
    components: {
      MuiButton: MuiButtonOverrides,
      MuiTabs: MuiTabsOverrides,
      MuiTab: MuiTabOverrides,
      MuiFormHelperText: MuiFormHelperText,
      MuiTextField: MuiTextField,
      MuiChip: MuiChipOverrides,
      MuiButtonGroup: MuiButtonGroupOverrides,
      MuiAlert: MuiAlertOverrides,
      MuiAccordion: MuiAccordionOverrides,
    },
    shape: {
      borderRadius: 6,
    },
  },
  csCZ, // x-data-grid translations
  pickersCsCZ, // x-date-pickers translations
  coreCsCZ // core translations
);

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Palette {
    yellow: Palette["primary"];
    blue: Palette["primary"];
    blueGrey800: Palette["primary"];
    green: Palette["primary"];
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface PaletteOptions {
    yellow: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    blueGrey800: PaletteOptions["primary"];
    green: Palette["primary"];
  }
}
