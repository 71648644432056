import { TagCategory } from "../../../model/tag/TagCategory";
import { Divider, Stack } from "@mui/material";
import { ItemDetailHeader } from "../../layout/ItemDetailHeader";
import { IconMenu } from "../../ui/menu/IconMenu";
import { Item } from "../../ui/menu/Item";
import { useDialog } from "../../../hooks/useDialog";
import { TagCategoryDeleteDialog } from "./dialogs/TagCategoryDeleteDialog";
import { TagCategoryDialog } from "./dialogs/TagCategoryDialog";
import React from "react";
import { NewTagForm } from "./ui/NewTagForm";
import { Tag } from "../../../model/tag/Tag";
import { Loading } from "../../ui/loading/Loading";
import { TagItem } from "./ui/TagItem";
import { Colors } from "../../../theme/colors";

type Props = {
  tagCategory: TagCategory;
  loadingTags: boolean;
  tags: Tag[];
  onUpdate: (updatedTagCategory: TagCategory) => void;
  onDelete: () => void;
  onTagUpdate: () => void;
};

export const TagCategoryDetailPage = ({ tagCategory, ...props }: Props) => {
  const [openDeleteDialog] = useDialog((dialogProps) => (
    <TagCategoryDeleteDialog {...dialogProps} tagCategory={tagCategory} onSuccess={props.onDelete} />
  ));
  const [openEditDialog] = useDialog((dialogProps) => (
    <TagCategoryDialog {...dialogProps} tagCategory={tagCategory} onSuccess={props.onUpdate} />
  ));

  return (
    <>
      <ItemDetailHeader title={tagCategory.title}>
        <IconMenu>
          <Item title={"Editovat"} onClick={openEditDialog} />
          <Item title={"Smazat kategorii"} onClick={openDeleteDialog} />
        </IconMenu>
      </ItemDetailHeader>
      <Stack width={"100%"} gap={2}>
        <NewTagForm tagCategory={tagCategory} onSuccess={props.onTagUpdate} />
        {(() => {
          if (props.loadingTags) {
            return <Loading />;
          }

          if (!props.tags.length) {
            return <i>V kategorii zatím nejsou žádné štítky.</i>;
          }

          return (
            <Stack divider={<Divider />} border={`1px solid ${Colors.divider}`} borderRadius="6px">
              {props.tags.map((tag) => (
                <TagItem key={tag.id} tag={tag} onUpdate={props.onTagUpdate} />
              ))}
            </Stack>
          );
        })()}
      </Stack>
    </>
  );
};
