import { Tag } from "../../../../model/tag/Tag";
import { useDialog } from "../../../../hooks/useDialog";
import React from "react";
import { TagDeleteDialog } from "../dialogs/TagDeleteDialog";
import { TagDialog } from "../dialogs/TagDialog";
import { IconButton, Stack } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import { IconColors } from "../../../../theme/utils";

type Props = {
  tag: Tag;
  onUpdate: () => void;
};

export const TagItem = ({ tag, ...props }: Props) => {
  const [openDeleteDialog] = useDialog((dialogProps) => (
    <TagDeleteDialog {...dialogProps} tag={tag} onSuccess={props.onUpdate} />
  ));
  const [openEditDialog] = useDialog((dialogProps) => (
    <TagDialog {...dialogProps} tag={tag} onSuccess={props.onUpdate} />
  ));

  return (
    <Stack direction={"row"} alignItems={"center"} py={1} px={1.5}>
      <Stack flex={1}>{tag.value}</Stack>
      <IconButton onClick={openEditDialog}>
        <Edit />
      </IconButton>
      <IconButton onClick={openDeleteDialog} sx={IconColors}>
        <Close />
      </IconButton>
    </Stack>
  );
};
