import { WardRoundBlock } from "./WardRoundBlock";
import { AbstractDataGrid } from "../../../ui/datagrid/AbstractDataGrid";
import { GridColDef } from "@mui/x-data-grid-premium";

import { ChallengeProgress } from "../../../../model/challenge/ChallengeProgress";
import { isBloodPressureValue } from "../../../../model/challenge/BloodPressureValue";
import { formatBloodPressure } from "../../../../helpers/formatBloodPressure";

interface Props {
  data: ChallengeProgress[];
}

export const WardRoundChallenges = ({ data }: Props) => {
  const columns: GridColDef<ChallengeProgress>[] = [
    {
      field: "type",
      headerName: "Název",
      flex: 1,
      renderCell: (params) => <strong>{params.value}</strong>,
    },
    {
      field: "value",
      headerName: "Naměřena hodnota",
      flex: 0.5,
      renderCell: (params) => (
        <strong>
          {isBloodPressureValue(params.row.value)
            ? formatBloodPressure(params.row.value)
            : params.row.value?.heartRate ?? "-"}
        </strong>
      ),
    },
  ];

  return (
    <WardRoundBlock title={"Výzvy"}>
      <AbstractDataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.type}
        disableColumnSorting
        disableColumnMenu
      />
    </WardRoundBlock>
  );
};
