import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { Patient } from "../../../../model/patient/Patient";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { CopyAllOutlined, EmailOutlined, Print, RefreshOutlined } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { convert } from "html-to-text";
import { useAlerts } from "../../../../hooks/useAlerts";
import { useAsyncCall } from "react-hook-async-call";
import { generatePassword } from "../../../../services/patient/generatePassword";
import { updatePassword } from "../../../../services/patient/updatePassword";
import { Organisation } from "../../../../model/Organisation";
import { Colors } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/utils";
import qrAppStore from "../../../../assets/appstore.png";
import qrGooglePlay from "../../../../assets/googleplay.png";

type Props = DialogProps & {
  patient: Patient;
  organisation?: Organisation;
};

export const OnboardingDialog = ({ patient, ...props }: Props) => {
  const subject = `${patient.care?.name} - vzdálené sledování pacienta ${patient.firstName} ${patient.lastName}`;
  const alerts = useAlerts();
  const contentRef = useRef<HTMLDivElement>(null);
  const contentRefCurrent = React.useCallback(() => contentRef.current, []);
  const showLoginInfo = props.organisation?.loginType === "default";

  const [setNewPassword, , loadingSetPassword] = useAsyncCall(updatePassword, undefined, undefined, alerts.error);
  const [resetPassword, password, loadingPassword] = useAsyncCall(
    generatePassword,
    undefined,
    (result) => setNewPassword(patient.id, result),
    alerts.error
  );

  const handlePasswordReset = () => {
    if (
      !!password ||
      window.confirm("Opravdu si přejete obnovit heslo pacienta? Tímto zneplatníte jeho aktuální heslo.")
    ) {
      resetPassword();
    }
  };

  const handlePrint = useReactToPrint({
    content: contentRefCurrent,
    documentTitle: subject,
    removeAfterPrint: true,
    pageStyle: "@page { size: A5 portrait; margin: 0; }",
  });

  const getTextContent = (): string => {
    if (!contentRef.current) {
      return "";
    }

    return convert(contentRef.current.innerHTML, {
      wordwrap: false,
      selectors: [
        { selector: "a", options: { hideLinkHrefIfSameAsText: true } },
        { selector: "p", options: { leadingLineBreaks: 2, trailingLineBreaks: 2 } },
        { selector: "h2", options: { leadingLineBreaks: 0, trailingLineBreaks: 0 } },
        { selector: "hr", options: { leadingLineBreaks: 0, trailingLineBreaks: 0 } },
        { selector: "table", format: "skip" },
        { selector: ".hide-in-text", format: "skip" },
        { selector: "ul", options: { itemPrefix: "    • " } },
      ],
    });
  };

  const handleEmail = () => {
    if (!patient) {
      return;
    }

    const to = patient.email;
    const body = encodeURIComponent(getTextContent());
    window.location.href = `mailto:${to}?subject=${subject}&body=${body}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getTextContent());
    alerts.success("Obsah byl zkopírován do schránky.");
  };

  const settingPassword = loadingPassword || loadingSetPassword;
  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      width={"md"}
      title={"Informace pro pacienta"}
      contentPadding={"0"}
      buttons={
        <Stack direction={"row"} flex={1}>
          <Button onClick={handleCopy} endIcon={<CopyAllOutlined />} variant={"outlined"} disabled={settingPassword}>
            Kopírovat obsah {showLoginInfo && !password && "bez hesla"}
          </Button>
          <Stack direction={"row"} gap={1} flex={1} justifyContent={"flex-end"}>
            {patient.email && (
              <Button onClick={handleEmail} endIcon={<EmailOutlined />} disabled={settingPassword}>
                Odeslat na {patient.email} {showLoginInfo && !password && "bez hesla"}
              </Button>
            )}
            <Button endIcon={<Print />} onClick={handlePrint} disabled={settingPassword}>
              Vytisknout {showLoginInfo && !password && "bez hesla"}
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Stack>
        {showLoginInfo && (
          <Stack
            gap={2}
            direction={"row"}
            alignItems={"center"}
            px={1.5}
            py={1.5}
            sx={{ backgroundColor: Colors.grey50, borderBottom: `1px solid ${Colors.divider}` }}
          >
            <Box sx={{ flex: 1, fontSize: 12, color: Colors.grey500 }}>
              Hesla pacientů z bezpečnostních důvodů neznáme.
              <br />V případě zapomenutého hesla obnovte heslo pacienta.
            </Box>
            <Button
              onClick={handlePasswordReset}
              variant={"outlined"}
              disabled={settingPassword}
              endIcon={<RefreshOutlined />}
            >
              Obnovit heslo pacienta
            </Button>
          </Stack>
        )}
        <Stack
          ref={contentRef}
          gap={2}
          p={3}
          sx={{
            table: { marginBottom: 2, marginX: "auto", borderSpacing: 0 },
            "table td": { textAlign: "center", paddingX: 2 },
            "table img": { width: 200 },
            "@media print": {
              "p, li": { fontSize: 13 },
              "table img": { width: "100px" },
              ".hide-in-print": { display: "none" },
            },
          }}
        >
          <Typography variant={"h2"} fontSize={22} className={"hide-in-text"}>
            {patient.care?.name}
          </Typography>
          <Typography>
            V rámci sledování dlouhodobé bezpečnosti a úspěšnosti Vaše léčby bychom Vás chtěli požádat o instalaci
            aplikace <strong>HealthWarden</strong>. Aplikace se bude dotazovat na Váš zdravotní stav a průběh léčby,
            případně Vám poskytne důležité informace ve formě virtuálních vizit. Vaše odpovědi následně zašleme Vašemu
            lékaři. Díky aplikaci můžeme lépe sledovat Váš zdravotní stav a data z virtuálních vizit pomůžou ve výzkumu
            dlouhodobé bezpečnosti a úspěšnosti léčby. Děkujeme a přejeme Vám hodně zdraví.
          </Typography>
          <Typography fontWeight={FontWeight.Bold} textAlign={"right"}>
            Tým ošetřujících lékařů, {props.organisation?.name}
          </Typography>
          <Divider />
          <Typography variant={"h2"} fontSize={18}>
            Práce s aplikaci HealthWarden
          </Typography>
          <ol style={{ margin: 0 }}>
            <li>
              Stáhněte aplikaci <strong>HealthWarden</strong>
              <ul className={"hide-in-print"}>
                <li>
                  Google Play (Android):{" "}
                  <a href="https://play.google.com/store/apps/details?id=blue.mild.healthwarden">
                    https://play.google.com/store/apps/details?id=blue.mild.healthwarden
                  </a>
                </li>
                <li>
                  AppStore (iPhone, iPad):{" "}
                  <a href="https://apps.apple.com/cz/app/healthwarden/id1639904637">
                    https://apps.apple.com/cz/app/healthwarden/id1639904637
                  </a>
                </li>
              </ul>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img src={qrGooglePlay} alt={"QR GooglePlay"} />
                    </td>
                    <td>
                      <img src={qrAppStore} alt={"QR AppStore"} />
                    </td>
                  </tr>
                  <tr>
                    <td>Google Play</td>
                    <td>AppStore</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              Po otevření aplikace vyberte organizaci <strong>{props.organisation?.name}</strong>
            </li>
            {showLoginInfo && (
              <>
                <li>
                  Do pole &quot;Uživatelské jméno&quot; zadejte <strong>{patient.username}</strong>
                </li>
                <li>
                  Do pole &quot;Heslo&quot; zadejte{" "}
                  {password ? <strong>{password}</strong> : <i style={{ color: Colors.grey500 }}>(heslo je skryté)</i>}
                </li>
              </>
            )}
            <li>Povolte aplikaci zasílání notifikací, přístup k fotoaparátu a ke zdravotním datům.</li>
          </ol>
          <Typography>
            Nyní je v aplikaci vidět první virtuální vizita. Po jejím dokončení se Vám v aplikaci zobrazí termín další
            vizity. Aplikace Vás vždy upozorní na případné další vizity. Stačí stačí se řídit instrukcemi a odeslat
            odpovědi na otázky, na které se Vás aplikace zeptá.
          </Typography>
          <Typography>V případě jakýchkoliv potíží nás neváhejte kontaktovat.</Typography>
        </Stack>
      </Stack>
    </AbstractDialog>
  );
};
